/* eslint-disable no-undef */
import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  graphql,
  navigate,
  useStaticQuery,
  Link,
} from 'gatsby';
import Img from 'gatsby-image';
import { useFlexSearch } from 'react-use-flexsearch';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const Search = () => {
  const data = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
      datoCmsSearch {
        accentColor {
          hex
        }
        backgroundColor
      }
    }
  `);
  const { index, store } = data.localSearchPages;
  const [query, setQuery] = useState();
  const [inputText, setInputText] = useState('');
  const inputRef = useRef();
  const resultsRef = useRef();
  const search = useFlexSearch(query, index, store);
  const resultsPluralized = (results) => (results.length === 1 ? 'result' : 'results');
  const initialMsg = 'Looking for something?';
  const resetMsg = 'Try another search?';
  const resultsMsg = 'You searched for';
  const noResultsMsg = 'No results for';
  const resultsDetailMsg = `Showing ${search.length} ${resultsPluralized(search)}`;
  const [accessibleStatusMsg, setAccessibleStatusMsg] = useState('');
  const [statusMsg, setStatusMsg] = useState(initialMsg);

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(inputText);
  };

  const clearSearch = () => {
    setInputText('');
    setQuery('');
    setStatusMsg(initialMsg);
    setAccessibleStatusMsg('');
    inputRef.current.value = '';
    inputRef.current.focus();
  };

  const handleKeyup = (e) => {
    if (e.key === 'Backspace') {
      setStatusMsg(initialMsg);
      setAccessibleStatusMsg('');
    }

    if (e.key === 'Escape') {
      clearSearch();
      setAccessibleStatusMsg('');
    }
  };

  useEffect(() => {
    const hasQuery = query ? Boolean(query.length) : false;
    const hasResults = Boolean(search.length);

    if (hasQuery && hasResults) {
      setStatusMsg(resultsMsg);
      setAccessibleStatusMsg(`${resultsMsg} ${query}, ${search.length} ${resultsPluralized(search)}`);
      resultsRef.current.focus();
    }

    if (hasQuery && !hasResults) {
      setStatusMsg(noResultsMsg);
      setAccessibleStatusMsg(`${noResultsMsg} ${query}`);
    }
  }, [search]);

  const handleClickThrough = (type, slug) => {
    const modelTypeName = type.replace('Single ', '').replace('Studio ', '');

    let slugPrefix = modelTypeName.replace(/\s+/g, '-').toLowerCase();
    if (slugPrefix === 'public-programs') {
      slugPrefix = 'public-program';
    }

    if (slugPrefix === 'publication') {
      slugPrefix = 'publications';
    }

    if (slugPrefix === 'edition') {
      slugPrefix = 'gertrude-editions';
    }

    return `/${slugPrefix}/${slug}`;
  };

  const removeSingle = (text) => text.replace('Single ', '');

  const handleExitPage = () => {
    if (window ?? false) {
      if (window.history.length) {
        window.history.go(-1);
      } else {
        navigate('/');
      }
    }
  };

  return (
    <Layout
      wrapperClass="search"
      bgcolor={data.datoCmsSearch.backgroundColor}
      accentColor={data.datoCmsSearch.accentColor}
    >
      <HelmetDatoCms title="Search" />
      <div className="search-top container row">
        <div className="col-sm-12 offset-sm-2 col">
          <p className="search-status">
            <span
              role="region"
              aria-live="polite"
              className="vh"
            >
              {accessibleStatusMsg}
            </span>
            <span>{statusMsg}</span>
          </p>
        </div>
      </div>
      <div className="search-bar container row">
        <div className="form-wrap col-sm-12 col">
          <form
            className="search-form"
            action="."
            onSubmit={(e) => { handleSearch(e); }}
          >
            <label
              htmlFor="searchInput"
              className="vh"
            >
              Search website
            </label>
            <input
              type="text"
              name="search"
              autoComplete="off"
              autoFocus
              id="searchInput"
              className="search-input"
              placeholder="Search"
              ref={inputRef}
              onKeyUp={(e) => handleKeyup(e)}
              onChange={(e) => setInputText(e.target.value)}
            />
            <button
              type="submit"
              aria-label="submit search"
              className="search-button"
            />
            <button
              type="button"
              aria-label="clear search"
              className={`${inputText.length ? 'on' : 'off'} btn clear-search`}
              onClick={clearSearch}
            />
          </form>
        </div>
        <nav aria-label="previous page" className="side-wrap col-sm-2 col">
          <button
            type="button"
            className="btn back-link"
            onClick={handleExitPage}
          >
            Return
          </button>
        </nav>
      </div>
      <section
        ref={resultsRef}
        aria-label="search results"
        className="search-results container row"
        tabIndex={search.length > 0 ? '0' : '-1'}
        style={{ display: query && query.length > 0 ? 'block' : 'none' }}
      >
        <div className="col-sm-12 offset-sm-2">
          <div className="results-detail col">
            {search.length > 0 && (
              <span className="results-summary">{resultsDetailMsg}</span>
            )}
            <button
              type="button"
              aria-label="clear search"
              className="btn clear-search"
              onClick={clearSearch}
            >
              <div className="sans--small link">
                {resetMsg}
              </div>
            </button>
          </div>
          <ul className="tiles tiles-4">
            {search.length > 0
              && search.map((result) => (
                <li key={result.id} className="tile col">
                  <Link
                    to={handleClickThrough(result.type, result.slug)}
                    aria-labelledby={result.id}
                  >
                    {result.image && (
											<ImageFadeIn
												src={result.image.url}
												alt={result.image.alt}
												className="featured-title-image" 
											/> 
                    )}
                    <div className="tile-text-wrap">
                      <p className="tile-category mb0">{removeSingle(result.type)}</p>
                      <span
                        id={result.id}
                        className="sans--regular result-title mb0"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: result.title,
                        }}
                      />
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </section>
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={data.datoCmsSearch.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

export default Search;
